import React from "react";
import "./Portrait.css";

function Portrait() {
    return(
        


        <div class="container-fluid py-3 portraitContainer">
            <div class="row">
                <div class="col col-lg-8 col-md-7 col-sm-12 col-12">
                    <h1>Kurzporträt</h1> 
                    <p><span>Bernd Burkhart</span>, Jahrgang 1969, wohnt im Dahner Felsenland.</p>
                    <p>Umgeben von viel Natur, in der er leidenschaftlich gerne wandert, lässt er sich immer wieder zu seinen Werken inspirieren. Das Malen begleitet ihn schon sein Leben lang. Bereits mit zehn Jahren wurde sein Zeichentalent von seinen Lehrern entdeckt und gefördert.</p>
                    <p>Ein Autodidakt - Zeichenkurse und Workshops waren nie seine Welt – Inspiration holt er sich lieber in der ihn umgebenden Natur. In seinem Atelier in Bruchweiler-Bärenbach bannt er seine Eindrücke auf Leinwand, Holz-Sperrholz-Altholz oder Papier. Dabei benutzt er die Verschiedensten Farben und Techniken. Momentan arbeitet er mit den natürlichen Strukturen von Holz-Sperrholz und Altholz.</p>
                    <p>Einige seiner Kunstwerke sind immer im Fluss, immer am sich verwandeln.</p>
                </div>
      

                <div class="col col-lg-4 col-md-5 col-sm-12 col-12">
                    <img className="image-fluid boxShadowLight" src={require("../images/portrait.jpg")} alt=""></img>
                </div>
            </div>
        </div>
    )
}

export default Portrait;