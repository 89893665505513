import React from "react"
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
return (
   
   <footer>
      <div class="container-fluid footer">

{/* The following icons are provided by FontAwesome (https://www.fontawesome.com). The icons are licensed under the Creative Commons Attribution 4.0 International license: https://fontawesome.com/license/free. All brand icons are trademarks of their respective owners. The use of these trademarks does not indicate endorsement of the trademark holder by Font Awesome, nor vice versa*/}
         <a href="https://www.facebook.com/people/Bernd-Burkhart/100015660424213"><i class="symbols fab fa-facebook-f"></i></a>
         <a href="mailto:bb@art-burkhart.de"><i class="symbols fas fa-envelope"></i></a>
         <p class="copyright"><Link to="/imprint" class="link">Impressum </Link>&nbsp; | &nbsp;© 2020 ART-Burkhart&nbsp; | &nbsp;<Link to="/privacy" class="link">Datenschutz</Link></p>
       </div>
   </footer>

)
}

export default Footer;