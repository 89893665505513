import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { allTitles, currentTitles, leinwandTitles, aquarellTitles, holzTitles, sonstigesTitles } from "./components/Database";

import Gallery from "./pages/Gallery";
import Current from "./pages/Current";
import Portrait from "./pages/Portrait";
import Contact from "./pages/Contact";
import PictureDetail from "./components/PictureDetail";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";

function Routes() {

   
    { useEffect(() => {
        window.scrollTo(0, 0);
      }, [useLocation().pathname])}

return (
    
    <Switch>
    
            <Route exact path="/" render={(props) => (<Current {...props} name={currentTitles}  />)}>
        </Route>
        <Route exact path="/gallery" render={(props) => (<Gallery {...props} name={allTitles}  />)}>
        </Route>
        <Route exact path="/gallery/Leinwand" render={(props) => (<Gallery {...props} name={leinwandTitles}  />)}>
        </Route>
        <Route exact path="/gallery/Aquarell" render={(props) => (<Gallery {...props} name={aquarellTitles}  />)}>
        </Route>
        <Route exact path="/gallery/Holz" render={(props) => (<Gallery {...props} name={holzTitles}  />)}>
        </Route>
        <Route exact path="/gallery/Sonstiges" render={(props) => (<Gallery {...props} name={sonstigesTitles}  />)}>
        </Route>
        <Route exact path="/Portrait" component={Portrait}>
        </Route>
        <Route exact path="/contact" component={Contact}>
        </Route>
        <Route exact path={"/imprint"} component={Imprint}>
        </Route>
        <Route exact path={"/privacy"} component={Privacy}>
        </Route>
        <Route path={"/gallery/:pictureName"} render={(props) => (<PictureDetail {...props} name={allTitles} />)}>
        </Route>
        <Route path={"/:pictureName"} render={(props) => (<PictureDetail {...props} name={allTitles} />)}>
        </Route>
      
    </Switch>
   
    
)
} 

export default Routes;