import React from "react";
import { carouselPictures } from "../components/Database";
import { Link } from "react-router-dom";
import GalleryPictures from "../components/GalleryPictures";
import "./Current.css";

function Current(props) {

  const carouselTitles = carouselPictures;

    return (
    <div>
      <div class="container-fluid">
        {/* <p class="currentTitle">Aktuell: Bilder auf Sperrholz mit Rahmen aus Altholz oder Eichenparkett</p> */}
      <div id="carouselExampleIndicators" class="carousel slide boxShadowLight" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" data-interval="7000">
            <Link to={carouselTitles[0].url}>
              <img src={require("../images/" + carouselTitles[0].filename)} class="d-block w-100 carouselImg image-fluid" alt="..." />
              <div class="carousel-caption d-none d-md-block">
                <h5>{carouselTitles[0].title}</h5>
                <p>{carouselTitles[0].description}</p>
              </div>
            </Link>
          </div>
  
          <div class="carousel-item" data-interval="7000">
            <Link to={carouselTitles[1].url}>
              <img src={require("../images/" + carouselTitles[1].filename)} class="d-block w-100 carouselImg image-fluid" alt="..." />
              <div class="carousel-caption d-none d-md-block">
                <h5>{carouselTitles[1].title}</h5>
                <p>{carouselTitles[1].description}</p>
              </div>
            </Link>
          </div> 
    
          <div class="carousel-item" data-interval="7000">
            <Link to={carouselTitles[2].url}>
              <img src={require("../images/" + carouselTitles[2].filename)} class="d-block w-100 carouselImg image-fluid" alt="..." />
              <div class="carousel-caption d-none d-md-block">
                <h5>{carouselTitles[2].title}</h5>
                <p>{carouselTitles[2].description}</p>
              </div>
            </Link>
          </div>
  
  
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
  
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>



      
    {/* <div class="container-fluid"> */}
      <div class="seperatorLine"></div>
    {/* </div> */}
  
    <GalleryPictures name={props.name}/>
      
  </div>
  )
}

export default Current;



