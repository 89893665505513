import React from "react";
import { Link } from "react-router-dom";
import "./GalleryPictures.css";

function GalleryPictures(props) {
  const titles = props.name;
 

    return (
      <div>
       <div class="container-fluid">
        <div class="card-columns">
         
         {titles.map((number) => 
          <div className="card filterShadow">
            <Link to={number.url}>
             <img className="image-fluid img" src={require("../images/" + number.filename)} alt=""></img>
               <div class="card-img-overlay d-flex align-items-center justify-content-center">
                <div>
                  <h5>{number.title}</h5>
                  <p>{number.description}</p>
                  <p>{number.size}</p>
                </div>
              </div>
            </Link>
          </div>      
         )}
        </div>
      </div>
    </div>
    )
   }

export default GalleryPictures;



