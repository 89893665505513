import React from "react";
import { Link } from "react-router-dom";
import Error404 from "../pages/Error404";
import "./PictureDetail.css";



function PictureDetail(props) {

    // const history = useHistory();
    const allPictures = props.name;
    const found = allPictures.find(element => element.url === props.match.params.pictureName);
    
  
        
    if (found !== undefined && found.type === "standard") {
        return (
            <div class="container-fluid pt-3">
              <div class="row">
                <div class="col col-lg-6 col-sm-12 col-12 text-center text-lg-left">
                <button class="imgDetail imgButton" data-toggle="modal" data-target="#image">
                    <img className="image-fluid imgDetail filterShadow" src={require("../images/" + found.filename)} alt=""></img>
                  </button>
                </div>
              
           
            <div class="modal fade" id="image" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal" role="document">
                <div class="modal-content">
                  <div class="modal-body p-0">
                   <img className="image-fluid img" src={require("../images/" + found.filename)} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
            

            <div class="col col-lg-6 col-sm-12 col-12 pl-5 d-flex align-items-center imgOverview text-center">
       
            <div class="container-fluid">

            
            <div class="seperatorLineTopPictureDetail" />
          
      
            
            
              <h2>{found.title}</h2> 
              <p>{found.size}</p>
              <p>{found.price}</p>
            
               {/* <p class="imgDescription">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p> */}
               <div class="seperatorLineBottomPictureDetail" />
            </div>
           
            </div>
            </div>
           
            <Link to="/gallery">
             <button type="button" class="btn btn-secondary bg-dark btn-lg backButton">Zurück</button>
            </Link>
          </div>

            
    
        )
    } else if (found !== undefined && found.type === "triptychonFluss") {
        return (
            <div class="container-fluid pt-3">
              <div class="row text-center">
                <div class="col col-lg-12 col-sm-12 col-12">
                  <button class="img imgButton" data-toggle="modal" data-target="#image">
                    <img className="image-fluid img filterShadow" src={require("../images/imflusstriptychon.jpg")} alt=""></img>
                  </button>
                </div>

            <div class="modal fade" id="image" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                  <div class="modal-body p-0"></div>
                    <img className="image-fluid img" src={require("../images/imflusstriptychon.jpg")} alt=""></img>
                </div>
              </div>
            </div>


            <div class="col col-lg-12 col-sm-12 col-12 pt-5 imgOverview">
                <h2>Im Fluss (3-teilig)</h2> 
                <p>{found.size}</p>
                <p>{found.price}</p>
                  <div class="seperatorLine" />
                  {/* <p class="imgDescription">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p> */}
            </div>

            </div>
             <Link to="/gallery">
              <button type="button" class="btn btn-secondary bg-dark btn-lg mt-3">Zurück</button>
             </Link>
            </div>
        )
    }

    else {
        return (
           <Error404 />
        )
    }
    
                       
  }
                    
export default PictureDetail;