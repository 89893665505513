import React from "react";
import "./Error404.css";

function Error404() {
    return(
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h2 class="errorPageText">Ups! Dieses Bild muss erst noch gemalt werden.</h2>
                </div>

                <div class="headerImgContainer">
                    <img style={{borderRadius: "0.25rem"}} class="img-fluid headerImg" src={require("../images/contact.jpg")} alt=""></img>
                </div>
            </div>

        </div>
    )
}

export default Error404;