// List of pictures & categories


// Standard Pictures

const aktInBlau = {
    filename: "aktinblau.jpg",
    url: "aktinblau",
    title: "AKT IN BLAU",
    description: "Holz / Mischtechnik",
    size: "61 x 122cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const blau = {
    filename: "blau.jpg",
    url: "blau",
    title: "BLAU.",
    description: "Holz / Mischtechnik",
    size: "100 x 76cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const burkhartwasser = {
    filename: "burkhartwasser.jpg",
    url: "burkhartwasser",
    title: "BURKHARTWASSER",
    description: "Acryl auf Holz",
    size: "69 x 95cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const feuerkatze = {
    filename: "feuerkatze.jpg",
    url: "feuerkatze",
    title: "FEUERKATZE",
    description: "Sperrholz mit Rahmen aus Altholz/Mischtechnik",
    size: "40 x 68cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const fruchtbarkeit = {
    filename: "fruchtbarkeit.jpg",
    url: "fruchtbarkeit",
    title: "FRUCHTBARKEIT",
    description: "Holz / Papier",
    size: "68 x 88cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const grueneFrau = {
    filename: "gruenefrau.jpg",
    url: "gruenefrau",
    title: "GRÜNE FRAU",
    description: "Leinwand / Mischtechnik",
    size: "40 x 80cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const herbst = {
    filename: "herbst.jpg",
    url: "herbst",
    title: "HERBST",
    description: "Leinwand / Mischtechnik / Collage",
    size: "60 x 90cm",
    price: "Verkauft",
    type: "standard"
}


const imFluss1 = {
    filename: "imfluss1.jpg",
    url: "imfluss1",
    title: "IM FLUSS 1",
    description: "Leinwand / Mischtechnik",
    size: "Triptychon - 240 x 90cm",
    price: "Preis auf Anfrage",
    type: "triptychonFluss"
}

const imFluss2 = {
    filename: "imfluss2.jpg",
    url: "imfluss2",
    title: "IM FLUSS 2",
    description: "Leinwand / Mischtechnik",
    size: "Triptychon - 240 x 90cm",
    price: "Preis auf Anfrage",
    type: "triptychonFluss"
}

const imFluss3 = {
    filename: "imfluss3.jpg",
    url: "imfluss3",
    title: "IM FLUSS 3",
    description: "Leinwand / Mischtechnik",
    size: "Triptychon - 240 x 90cm",
    price: "Preis auf Anfrage",
    type: "triptychonFluss"
}

const kundalinigewitter = {
    filename: "kundalinigewitter.jpg",
    url: "kundalinigewitter",
    title: "KUNDALINIGEWITTER",
    description: "Holz / Mischtechnik",
    size: "122 x 155cm",
    price: "Preis auf Anfrage",
    type: "standard"
}



const naturverliebt = {
    filename: "naturverliebt.jpg",
    url: "naturverliebt",
    title: "NATUR VERLIEBT",
    description: "Leinwand / Mischtechnik",
    size: "80 x 140cm",
    price: "Verkauft",
    type: "standard"
}

const neuanfang = {
    filename: "neuanfang.jpg",
    url: "neuanfang",
    title: "NEUANFANG",
    description: "Leinwand / Mischtechnik",
    size: "90 x 60cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const ohneTitel = {
    filename: "ohnetitel.jpg",
    url: "ohnetitel",
    title: "OHNE TITEL",
    description: "Leinwand / Mischtechnik",
    size: "3 mal 60 x 150cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const ohneTitel2 = {
    filename: "ohnetitel2.jpg",
    url: "ohnetitel2",
    title: "OHNE TITEL",
    description: "Aquarell mit Rahmen",
    size: "50 x 70cm",
    price: "Preis auf Anfrage",
    type: "standard"
}
    

const ohneTitel3 = {
    filename: "ohnetitel3.jpg",
    url: "ohnetitel3",
    title: "OHNE TITEL",
    description: "Aquarell auf Pappe",
    size: "47 x 64cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const ohneTitel4 = {
    filename: "ohnetitel4.jpg",
    url: "ohnetitel4",
    title: "OHNE TITEL",
    description: "Acryl auf Leinwand",
    size: "80 x 80cm, Gemeinschaftsarbeit mit Ann-Kathrin Hartmann",
    price: "Verkauft",
    type: "standard"
}

const phase1bis3 = {
    filename: "phase1bis3.jpg",
    url: "phase1bis3",
    title: "PHASE 1 BIS 3",
    description: "Sperrholz mit Rahmen aus Altholz / Mischtechnik",
    size: "47 x 77cm",
    price: "Preis auf Anfrage",
    type: "standard"
}


const phase1 = {
    filename: "phase1.jpg",
    url: "phase1",
    title: "PHASE 1",
    description: "Sperrholz mit Rahmen aus Eichenparkett",
    size: "50 x 81cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const phase2 = {
    filename: "phase2.jpg",
    url: "phase2",
    title: "PHASE 2",
    description: "Sperrholz mit Rahmen aus Eichenparkett",
    size: "50 x 74cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const phase3 = {
    filename: "phase3.jpg",
    url: "phase3",
    title: "PHASE 3",
    description: "Sperrholz mit Rahmen aus Eichenparkett",
    size: "50 x 74cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const popArt = {
    filename: "popart.jpg",
    url: "popart",
    title: "POP ART",
    description: "Leinwand / Acryl",
    size: "60 x 80cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

const spinning = {
    filename: "spinning.jpg",
    url: "spinning",
    title: "SPINNING",
    description: "Leinwand / Mischtechnik",
    size: "140 x 100cm",
    price: "Preis auf Anfrage",
    type: "standard"
}

//Triptychons

// const imFlussTriptychon = {
//     filename: "imflusstriptychon.jpg",
//     url: "imfluss",
//     title: "IM FLUSS - TRIPTYCHON",
//     description: "Leinwand / Mischtechnik",
//     size: "Triptychon - 240 x 90cm",
//     price: "Preis auf Anfrage",
//     type: "triptychonFluss"
// }




const carouselPictures = [kundalinigewitter, imFluss2, ohneTitel2]
const currentTitles = [phase1, phase2, phase3, blau, phase1bis3, feuerkatze];

const leinwandTitles = [imFluss1, ohneTitel, ohneTitel4, neuanfang, herbst, imFluss2, spinning, grueneFrau, imFluss3, naturverliebt, popArt];
const aquarellTitles = [ohneTitel2, ohneTitel3];
const holzTitles = [phase1, phase2, phase3, blau, phase1bis3, feuerkatze, burkhartwasser, fruchtbarkeit, kundalinigewitter, aktInBlau];
const sonstigesTitles = [];

const allTitles = [imFluss1, fruchtbarkeit, kundalinigewitter, neuanfang, feuerkatze, phase1, phase2, phase3, blau, imFluss2, ohneTitel4, aktInBlau, burkhartwasser, spinning, phase1bis3, naturverliebt, imFluss3, herbst, grueneFrau, popArt, ohneTitel, ohneTitel2, ohneTitel3];




export { carouselPictures, allTitles, currentTitles, leinwandTitles, aquarellTitles, holzTitles, sonstigesTitles};