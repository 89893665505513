import React from "react";
import { NavLink } from "react-router-dom";
import GalleryPictures from "../components/GalleryPictures";
import "./Gallery.css";


function Gallery(props) {
return (
    <div>
        <div class="container-fluid">
            <ul class="row list-group list-group-horizontal justify-content-center">
                <NavLink exact to="/gallery" className="col-lg-2 col-md-2 col-sm-4 col-4 list-group-item list-group-item-action" activeClassName="active">Alle Bilder</NavLink>
                <NavLink to="/gallery/Leinwand" className="col-lg-2 col-md-2 col-sm-4 col-4 list-group-item list-group-item-action" activeClassName="active">Leinwand</NavLink>
                <NavLink to="/gallery/Aquarell" className="col-lg-2 col-md-2 col-sm-4 col-4 list-group-item list-group-item-action list-group-item-3" activeClassName="active">Aquarell</NavLink>
                <NavLink to="/gallery/Holz" className="col-lg-2 col-md-2 col-sm-4 col-4 list-group-item list-group-item-action list-group-item-4" activeClassName="active">Auf Holz</NavLink>
                <NavLink to="/gallery/Sonstiges" className="col-lg-2 col-md-2 col-sm-4 col-4 list-group-item list-group-item-action" activeClassName="active">Sonstiges</NavLink>   
            </ul>
        </div>

        <GalleryPictures name={props.name}/>
    </div>
)
}

export default Gallery;