import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

function Header() {
  return (

  <header>
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
      <a class="navbar-brand" href="/">
        <img src={require("../images/logo.jpg")} alt=""/>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
       <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item ml-2" data-toggle="collapse" data-target=".navbar-collapse.show">
           <NavLink  exact to="/" className="nav-link" activeClassName="active">START</NavLink>
          </li>
          <li class="nav-item ml-2" data-toggle="collapse" data-target=".navbar-collapse.show">
            <NavLink to="/gallery" className="nav-link" activeClassName="active">BILDERGALERIE</NavLink>
          </li>
          <li class="nav-item ml-2" data-toggle="collapse" data-target=".navbar-collapse.show">
            <NavLink to="/portrait" className="nav-link" activeClassName="active">PORTRÄT</NavLink>
          </li>
          <li class="nav-item ml-2" data-toggle="collapse" data-target=".navbar-collapse.show">
            <NavLink  to="/contact" className="nav-link" activeClassName="active">KONTAKT</NavLink>
           </li>
         </ul>                    
       </div>       
      </nav>
  </header>
      
    
   
  );
}

export default Header;