import React from "react";
import "./Miscellaneous.css";

function Contact() {
    return (
        <div>
          <div class="container-fluid py-3">
            <div class="row">
              <div class="headerImgContainer">
                <h1>KONTAKT</h1>
                  <img class="img-fluid headerImg" src={require("../images/contact.jpg")} alt=""></img>
              </div>
            </div>    
          </div>

        <div class="container-fluid miscContainer py-5">
          <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
              <h3>Kontaktieren Sie mich</h3>
              <p>Gerne beantworte ich Ihre Fragen per Mail oder telefonisch.</p>
              <div>
                <h4>Kontakt</h4>
                <p>ART Burkhart</p>
                <p>Bernd Burkhart</p>
                <p>Lauterstraße 8</p>
                <p>76891 Bruchweiler/Bärenbach</p><br />
                <p>Telefon: +49 176 833 05 888</p>
                <p>E-Mail:
                  <a href="mailto:bb@art-burkhart.de">bb@art-burkhart.de</a>
                </p>
              </div>
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-12 contactFormContainer">
              <form action="mailto:bb@art-burkhart.de?subject=Kontaktanfrage ART-Burkhart" method="post" enctype="text/plain">
                <div class="form-group">
                  <input type="text" class="form-control" name="Name" placeholder="Name" />
                </div>
                <div class="form-group">
                  <input type="email" class="form-control" aria-describedby="emailHelp" name="E-Mail Adresse" placeholder="E-Mail" />
                </div>
                <div class="form-group">
                <input type="text" class="form-control" name="Telefon" placeholder="Telefon" />
                </div>
                <div class="form-group">
                <textarea class="form-control" rows="7" name="Ihre Nachricht" placeholder="Ihre Nachricht"></textarea>
                </div>
              <button type="submit" class="btn btn-dark">Absenden</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
}


export default Contact;